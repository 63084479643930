.App {
  text-align: center;
}

.App-logo {
  height: 150px;
  pointer-events: none;
  margin-top: 20px;
}

.navbar-fdd {
  height: 100px;
}

.bodyContainer {
}



footer {
  background-color: black;
  padding: 2%;
  color: white;
}
footer a {
  color: yellow;
}
footer a:hover {
  color: yellow;
}

.whatsapp {
  position: fixed;
  bottom: 15px;
  right: 15px;
  overflow: hidden;
  z-index: 9999;
}
.whatsapp img {
  cursor: pointer;
}
.whatsapp img:hover {
  filter: brightness(1.5);
}
.whatsapp img {
  height: 150px;
}
.contact-social i {
  font-size: 35px;
  margin: 15px;
}
i.fab.fa-instagram {
  color: #ce298a;
}
i.fab.fa-instagram:hover {
  color: #ff84cc;
}
i.fab.fa-whatsapp {
  color: #4baa2b;
}
i.fab.fa-whatsapp:hover {
  color: #6ef740;
}
@media (max-width: 960px) {
  .navbar-fdd {
    height: 100%;
  }

  .whatsapp img {
    height: 100px;
  }
}
.sticky-container {
  position: fixed;
  right: 0px;
  top: 230px;
  width: 80px;
  z-index: 1100;
}
.sticky {
  font-size: x-large;
}
.sticky li {
  list-style-type: none;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  text-align: center;
}

.sticky .facebook {
  background: #4267b2;
  padding: 3px;
}
.sticky .instagram {
  background: #ce298a;
  padding: 3px;
}
.sticky .whatsapp-s {
  background: #4baa2b;
  padding: 3px;
}
.sticky i {
  color: white !important;
}

.sticky li:hover {
  margin-left: -10px;
}

.fixed-button {
  background-color: #00c9a3;
  position: fixed;
  left: 15px;
  bottom: 15px;
  padding: 6px 20px;
  border-radius: 30px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: Nunito, sans-serif;
  line-height: 2.25rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);
}
.fixed-button:hover {
  text-decoration: none;
  background-color: #00bfa5;
  color: white;
}


