
label {
  font-family: 'Abel', sans-serif;
  letter-spacing: -.08rem;

}
textarea.form-control {
  resize: none;
}

.titulo {
  font-family: 'Alfa Slab One', cursive;
  text-transform: uppercase;
  background: linear-gradient(-90deg, #f57d26, #f5b667);
  border-radius: 5px;
  font-size: 3.8rem;
  color: #FF0;
  padding: 30px;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  margin: 0;
}



.subtitulo {
  font-family: 'Alfa Slab One', cursive;
  text-transform: uppercase;
  background: linear-gradient(-90deg, #f57d26, #f5b667);
  border-radius: 5px;
  font-size: 40px;
  color: #FF0;
  padding: 20px;
  margin-top: 30px;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.5);
}

.respuesta {
  border-radius: 5px;
  text-align: center;
  padding: 25px;
}


@media (max-width: 960px) {
  .titulo {
      font-size: 3rem;
  }
  .subtitulo {
    font-size: 35px;

  }
}